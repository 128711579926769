import React from 'react';

import Layout from '../components/Layout';

import { StaticImage } from 'gatsby-plugin-image';

const OurStory = (props) => (
  <Layout fullMenu location={props.location} title="Our Story">
    <article id="main">
      <header>
        <h2>Our Story</h2>
        {/* <p>Aliquam ut ex ut interdum donec amet imperdiet eleifend</p> */}
      </header>
      <section className="wrapper style5 story">
        <div className="inner">
          <p>
            <span className="image left headshot">
              <StaticImage
                src="../assets/images/denny-headshot-square.JPG"
                alt="denny"
                placeholder="blurred"
              />
            </span>
            <b>Denny: </b>Kathy and I met when we were both helping out at a
            TNTT training camp. During one of the lessons, she noticed I was
            feeling hot and let me borrow a fan. I never had a chance to give
            back her fan before she left camp. She had told me that she was
            attending school in Austin, so I used the excuse of returning her
            fan to meet up with her. After returning the fan she was still
            interested in hanging out with me so we continued to meet up, got to
            know each other better, and started dating.
          </p>
          <br />
          <p>
            <span className="image right jerusalem">
              <StaticImage
                src="../assets/images/Jerusalem.jpg"
                alt="jerusalem"
                placeholder="blurred"
              />
            </span>
            A highlight of our relationship was the TNTT Pilgrimage to Rome and
            the Holy Land that we went on together. From this experience, I knew
            I had found the One, someone who could share with me in everything
            that mattered the most in my life.
          </p>
          <br />
          <hr />

          <p>
            <span className="image left headshot">
              <StaticImage
                src="../assets/images/kathy-headshot-square.JPG"
                alt="kathy"
                placeholder="blurred"
              />
            </span>
            <b>Kathy: </b>Despite many opportunities for our paths to cross, it
            didn’t happen until a VEYM training camp. Upon my arrival, I met up
            with some hometown friends who were congratulating me. Not wanting
            to be a spectator, Denny joined in with them where he learned that I
            recently moved to Austin and received my UTCOP white coat. From that
            moment on, he definitely caught my attention.
          </p>
          <p>
            During a training lesson, I noticed Denny struggling with the Texas
            heat and offered my fan to him. On the last day of camp, the closing
            ceremony was taking longer than usual. Once it ended, I did not say
            any goodbyes and immediately left. As a result, Denny returned the
            fan to me back in Austin where our relationship blossomed.
          </p>
          <p>
            It was easy to fall more “in like” with Denny from every encounter.
            Our conversations flowed to the point where we both lost track of
            time. When we met to hang out, I always enjoyed his company. He was
            quirky, cute, bashful, and much more! Then one day, I realized that
            I have fallen in love with Denny. He’s my kind-of perfect guy who I
            can’t wait to spend the rest of my life with! Forever and ever ❤
          </p>
        </div>
      </section>
    </article>
  </Layout>
);

export default OurStory;
